.archive-title {
  font-size: 20px;
  font-family: 'Antonio', sans-serif;
}

.archive-year {
  font-family: 'Satisfy', cursive;
  font-size: 24px;
  text-align: center;
  display: block;
  padding-top: 10px;
}