.page-subheading {
    font-size: 24px;
}
.page-h3 {
    font-size: 18px;
}
.page-contact-email {
    font: inherit;
    display: block;
    border-radius: 4px;
    margin-bottom: 30px;
    border: 1px solid rgba(10, 10, 10,.1);
    padding: 8px 15px;
    width: 100%;
    box-sizing: border-box;
}
.page-contact-message {
    font: inherit;
    display: block;
    border-radius: 4px;
    margin-bottom: 30px;
    border: 1px solid rgba(10, 10, 10,.1);
    padding: 8px 15px;
    width: 100%;
    box-sizing: border-box;
}
.page-contact-send {
    font-family: "Source Sans Pro", Verdana, sans-serif;
    font-size: inherit;
    font-weight: 600;
    color: rgba(10, 10, 10,.76);
    background-color: rgba(10, 10, 10,.05);
    border-radius: 4px;
    padding: 8px 15px;
    width: 100%;
    border: 1px solid rgba(10, 10, 10,.1);
    cursor: pointer;
}
