.product-block {
  width: 100%;
}
.product-card-image.mdl-card {
  width: 100%;
  max-height: 256px;
}
.product-card-image > .mdl-card__actions {
  padding: 16px;
  background: rgba(0, 0, 0, 0.3);
}
.product-card-image-name {
  color: #fff;
  font-size: 20px;
  text-decoration: none;
}
.product-card-description.mdl-card {
  width: 100%;
  max-height: 256px;
  background: #fafafa;
  margin-bottom: 16px;
 }
 .product-card-description > .mdl-card__actions {
   border-color: rgba(255, 255, 255, 0.2);
 }
 .product-card-description > .mdl-card__title {
   align-items: flex-start;
 }
 .product-card-description > .mdl-card__title > h4 {
   margin-top: 0;
 }
 .product-card-description > .mdl-card__actions {
   display: flex;
   box-sizing:border-box;
   align-items: center;
   border-top: 1px solid rgba(0,0,0,0.2);
 }
 .product-card-description > .mdl-card__actions > .material-icons {
   padding-right: 10px;
 }
 .product-card-description > .mdl-card__title,
 .product-card-description > .mdl-card__actions,
 .product-card-description > .mdl-card__actions > .mdl-button,
 .product-card-description > .mdl-card__actions > a > .material-icons {
   color: #565656;
 }