footer {
    .mdl-mini-footer__left-section {
        & img {
            height: 100px;
          }
    }

    .mdl-mini-footer__link-list {
        & a  {
            cursor: pointer;
            transition: color .2s ease-in;
            &:hover {
                color: #222;
            }
        }
    }

    .copyright {
        margin-top: var(--spacing-unit);
    }

    .outdoorsnow-logo {
        pointer-events: none;
        display: inline-block;
    }
}
.outdoorsnow-footer {
    background-color: var(--footer-background);

    & span {
        color: #333;
    }
}
