.kss-paginator {
    padding: 16px;
    font-size: 18px;
    font-weight: 400;
    text-decoration: none;
}
.kss-prev-page {
    float: left;

}
.kss-next-page {
    float: right;
}