.post-card {
  background: #fff;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;

  & a {
    text-decoration: none;
  }

  & hr {
    margin-left: 5px;
    margin-right: 5px;
  }

  & .mdl-card {
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    height: 100%;
    width: 100%;

    & .mdl-card__media {
      background: #fff;
      & img {
          display: block;
          width: 100%;
          height: auto;
      }
    }

    &:hover {
      .mdl-card__media img {
        -webkit-filter: brightness(106%);
        filter: brightness(106%);
      }
    }
  }
}