.mdl-layout__header {
    background-color: var(--body-background);

    & .first-header-row {
        min-height: 73px;

        & a > .outdoorsnow-logo {
            pointer-events: none;
        }

        & img {
            height: 70px;
        }
    }

    & .second-header-row {
        height: 40px;
    }
}

.outdoorsnow-nav-link {
    & a {
        cursor: pointer;
        transition: color .2s ease-in;

        &:hover {
            color: #111;
        }
    }
}