.post {
    background: #fff;
    padding: calc(var(--spacing-unit)/2);
    margin: calc(var(--spacing-unit)/2) 0 calc(var(--spacing-unit)/2) 0;
}

.post-header {
    padding-bottom: calc(var(--spacing-unit)/2);

    & img {
        display: block;
        width: 100%;
        height: auto;
    }
}

.post-title {
    font-family: 'Antonio', sans-serif;
    padding-top: calc(var(--spacing-unit)/2);
    font-size: 30px;
    text-transform: uppercase;
}

.post-meta {
    font-family: 'Satisfy', cursive;
    color: #999;
}

.post-categories {
    color: #999;
}