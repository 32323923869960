/**
 * Reset some basic elements
 */
body, h1, h2, h3, h4, h5, h6,
p, blockquote, pre, hr,
dl, dd, ol, ul, figure {
  margin: 0;
  padding: 0;
}

/**
 * Body
 */

body {
  background-color: var(--body-background);
  font-family: var(--main-font);
  font-weight: 300;
}

/**
 * Set `margin-bottom` to maintain vertical rhythm
 */
h1, h2, h3, h4, h5, h6,
p, blockquote, pre,
ul, ol, dl, figure,
%vertical-rhythm {
  margin-bottom: calc(var(--spacing-unit) / 2);
}

/**
 * Images
 */
img {
  max-width: 100%;
  vertical-align: middle;
}

/**
 * Figures
 */
figure > img {
  display: block;
}

/**
 * Lists
 */
ul, ol {
  margin-left: var(--spacing-unit);
}

li {
  > ul,
  > ol {
    margin-bottom: 0;
  }
}

/**
 * Headings
 */

h1, h2, h3, h4, h5, h6 {
  font-weight: 300;
}

code {
  padding: 1px 5px;
}

pre {
  padding: 8px 12px;
  overflow-x: scroll;

  > code {
    border: 0;
    padding-right: 0;
    padding-left: 0;
  }
}

/**
 * Wrapper
 */
.wrapper {
  max-width: -webkit-calc(#{var(--content-width)} - (#{var(--spacing-unit)} * 2));
  max-width: calc(#{var(--content-width)} - (#{var(--spacing-unit)} * 2));
  margin-right: auto;
  margin-left: auto;
  padding-right: var(--spacing-unit);
  padding-left: var(--spacing-unit);
  @extend %clearfix;

  @include media-query(var(--on-laptop)) {
    max-width: -webkit-calc(#{var(--content-width)} - (#{var(--spacing-unit)}));
    max-width: calc(#{var(--content-width)} - (#{var(--spacing-unit)}));
    padding-right: calc(var(--spacing-unit) / 2);
    padding-left: calc(var(--spacing-unit) / 2);
  }
}

.page-content {
  box-sizing: border-box;
  min-height: calc(100% - 160px);
}

.main-layout {
  height: 100%;
}

/**
 * Clearfix
 */
%clearfix {

  &:after {
    content: "";
    display: table;
    clear: both;
  }
}